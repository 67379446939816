import React from 'react';
import { Layout, Row, Col, Menu, Typography } from 'antd';
import { PhoneOutlined, ShopOutlined } from '@ant-design/icons';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { tintColorLight } from '../constants/colors';
import { navigationMenus } from '../constants/config';
import { createStyle } from '../../utils/tools';
import FooterFeatures from './widgets/FooterFeatures';
import Divider from './common/Divider';

const { Footer } = Layout;
const { Title, Paragraph } = Typography;

const PageFooter = ({ location }) => {
  const { t } = useTranslation();

  const renderNavMenu = navigationMenus.map((item) => {
    return (
      <Menu.Item style={styles.menuItem} key={item.name}>
        <span style={styles.dot}></span>
        <Link to={item.route + location.search}>{t(item.name)}</Link>
      </Menu.Item>
    );
  });

  return (
    <Footer className="page-footer" style={{ backgroundColor: 'white' }}>
      <FooterFeatures />
      <Divider />
      <Row justify="space-around" style={{ paddingTop: 16 }}>
        <Col span={24} md={12} lg={5} style={styles.column}>
          <div style={styles.logoContainer}>
            <img
              style={styles.logo}
              src="/images/logo-green-vertical.png"
              alt="Luniu Mall Logo"
            />
          </div>
        </Col>
        <Col span={12} lg={5} style={styles.column}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {t('footer.sitemap')}
          </Title>
          <Menu style={styles.menu}>{renderNavMenu}</Menu>
        </Col>

        <Col span={12} lg={5} style={styles.column}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {t('footer.support')}
          </Title>
          <div style={styles.qrCodeContainer}>
            <img
              style={styles.qrCode}
              src="/images/support/new-wechat-account.png"
              alt="qr code for wechat official account"
            />
            <Paragraph>{t('footer.wechatAccount')}</Paragraph>
          </div>
          <div style={styles.qrCodeContainer}>
            <img
              style={styles.qrCode}
              src="/images/support/wechat-xiaohua.png"
              alt="qr code for wechat customer support"
            />
            <Paragraph>{t('footer.customerSupport')}</Paragraph>
          </div>
        </Col>
        <Col span={24} md={12} lg={5} style={styles.column}>
          <Title level={5} style={{ textAlign: 'center' }}>
            {t('footer.contactInfo')}
          </Title>
          <div>
            <Paragraph style={styles.subTitle}>
              <ShopOutlined />
              {t('footer.office')}
            </Paragraph>
            <Paragraph>12155 Riverside Way,Richmond, BC, Canada</Paragraph>
          </div>
          <div>
            <Paragraph style={styles.subTitle}>
              <ShopOutlined />
              {t('footer.farm')}
            </Paragraph>
            <Paragraph>3345 224th Street, Langley, BC, Canada</Paragraph>
          </div>
          <div>
            <Paragraph style={styles.subTitle}>
              <PhoneOutlined />
              {t('footer.phone')}
            </Paragraph>
            <a
              href="tel:1-604-275-9642"
              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
            >
              1-604-275-9642
            </a>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
          Copyright © 2020 Luniu Mall {' - '}
          <Link to={'/privacy-policy' + location.search}>
            {t('privacyPolicy.title')}
          </Link>
        </Paragraph>
      </Row>
      <Row justify="center">
        <Paragraph>
          <Link
            target="_blank"
            style={{ fontSize: 8.5 }}
            to="https://beian.miit.gov.cn/"
          >
            {t('privacyPolicy.icp')}
          </Link>
        </Paragraph>
      </Row>
    </Footer>
  );
};

const styles = createStyle({
  menu: {
    backgroundColor: 'transparent',
  },
  menuItem: {
    margin: '0 auto',
    paddingLeft: 0,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  dot: {
    width: 5,
    height: 5,
    backgroundColor: tintColorLight,
    display: 'block',
    borderRadius: '50%',
    marginRight: 5,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 200,
    height: 200,
  },
  qrCodeContainer: {
    width: 'fit-content',
    margin: '0 auto',
    textAlign: 'center',
  },
  qrCode: {
    width: 120,
    margin: '0 auto',
  },
  subTitle: {
    color: tintColorLight,
    marginBottom: 0,
  },
  column: {
    marginBottom: 10,
  },
});

export default PageFooter;
